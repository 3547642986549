import React from "react";
import { motion } from "framer-motion";
import { checkIcon } from "../../../Base/SVG";
export default function WebApp() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
      className="projectsTab"
    >
      <div className="projectsWeb__wrapper">
        <div className="projectsWeb__bg">
          <img src="/images/projects/bg.png" alt="" />
        </div>
        <div className="projectsWeb">
          <div className="projectsWeb__inner">
            <div className="projectsWeb__title">
              <h3>Learning Management System</h3>
            </div>
            <div className="projectsWeb__item">
              <h6 className="sm">Technologies we used</h6>
              <div className="projectsWeb__tech">
                <span>
                  <img src="/images/projects/react.svg" alt="" />
                </span>
                <span>
                  <img src="/images/projects/php.svg" alt="" />
                </span>
                <span>
                  <img src="/images/projects/js.svg" alt="" />
                </span>
                <span>
                  <img src="/images/projects/laravel.svg" alt="" />
                </span>
              </div>
            </div>
            <div className="projectsWeb__item">
              <h6 className="sm">Project Status</h6>
              <div className="progress__wrapper">
                <div className="progress">
                  <div className="progress__inner">
                    <div className="progress__bar" style={{ maxWidth: "100%" }}>
                      <span>{checkIcon}</span>
                    </div>
                  </div>
                </div>
                <p>100%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
