import React, { useState } from "react";

import Mobile from "./Mobile";
import WebApp from "./WebApp";

export default function Projects() {
  const [tab, setTab] = useState("mobileApp");
  return (
    <section className="projects" id={"projects"}>
      <div className="divider">
        <img src="/images/divider.svg" alt="" />
        <img className="mob" src="/images/divider-mob.svg" alt="" />
      </div>
      <div className="divider rev">
        <img src="/images/divider.svg" alt="" />
        <img className="mob" src="/images/divider-mob.svg" alt="" />
      </div>
      <div className="auto__container">
        <div className="projects__inner">
          <div className="projects__inner-title">
            <h2>
              <span>Our</span>
              <b>Projects</b>
            </h2>
            <div className="tab__wrapper">
              <div className="tab">
                <button
                  type="button"
                  className={tab === "mobileApp" ? "active" : ""}
                  onClick={() => setTab("mobileApp")}
                >
                  Mobile Apps
                </button>
                <button
                  type="button"
                  className={tab === "webApp" ? "active" : ""}
                  onClick={() => setTab("webApp")}
                >
                  Web Apps
                </button>
              </div>
            </div>
          </div>
          {tab === "mobileApp" && <Mobile />}
          {tab === "webApp" && <WebApp />}
        </div>
      </div>
    </section>
  );
}
