export const chevronBottom = (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.589 13.0892C10.4328 13.2454 10.2208 13.3332 9.99988 13.3332C9.77891 13.3332 9.56699 13.2454 9.41071 13.0892L4.69655 8.375C4.61695 8.29813 4.55347 8.20617 4.50979 8.1045C4.46612 8.00283 4.44313 7.89348 4.44217 7.78284C4.44121 7.67219 4.46229 7.56245 4.50419 7.46004C4.5461 7.35763 4.60797 7.26458 4.68622 7.18634C4.76446 7.10809 4.8575 7.04622 4.95992 7.00432C5.06233 6.96242 5.17206 6.94133 5.28271 6.94229C5.39336 6.94325 5.50271 6.96624 5.60438 7.00992C5.70605 7.05359 5.79801 7.11708 5.87488 7.19667L9.99988 11.3217L14.1249 7.19667C14.282 7.04487 14.4925 6.96087 14.711 6.96277C14.9295 6.96467 15.1386 7.05231 15.2931 7.20682C15.4476 7.36133 15.5352 7.57034 15.5371 7.78883C15.539 8.00733 15.455 8.21783 15.3032 8.375L10.589 13.0892Z"
      fill="currentColor"
    />
  </svg>
);
export const listCheck = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0001 21C14.0823 21 16.1001 20.278 17.7096 18.957C19.3192 17.6361 20.4209 15.7979 20.8271 13.7557C21.2333 11.7136 20.9188 9.5937 19.9373 7.75737C18.9557 5.92104 17.3678 4.48187 15.4441 3.68506C13.5204 2.88825 11.3799 2.78311 9.38739 3.38756C7.39486 3.992 5.67355 5.26863 4.51675 6.99992C3.35996 8.73121 2.83925 10.81 3.04336 12.8822C3.24746 14.9544 4.16375 16.8917 5.63609 18.364"
      stroke="url(#paint0_linear_158_7874)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16 10L12.402 14.318C11.747 15.104 11.419 15.498 10.978 15.518C10.538 15.538 10.175 15.175 9.451 14.451L8 13"
      stroke="url(#paint1_linear_158_7874)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_158_7874"
        x1="3"
        y1="3"
        x2="21"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF8F" />
        <stop offset="1" stopColor="#00A1FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_158_7874"
        x1="8"
        y1="10"
        x2="16"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF8F" />
        <stop offset="1" stopColor="#00A1FF" />
      </linearGradient>
    </defs>
  </svg>
);
export const checkIcon = (
  <svg viewBox="0 0 12 12" fill="none">
    <path
      d="M4.50002 10.2104L1.39502 7.10543L2.81002 5.69043L4.50002 7.38543L9.44002 2.44043L10.855 3.85543L4.50002 10.2104Z"
      fill="white"
    />
  </svg>
);
export const checkSm = (
  <svg viewBox="0 0 14 10" fill="none">
    <path
      d="M1.5 5L5.5 9L12.1667 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const closeIcon = (
  <svg viewBox="0 0 25 25" fill="none">
    <path
      d="M10.8428 12.5L6.20411 7.86135C5.98396 7.64158 5.86013 7.34337 5.85985 7.0323C5.85958 6.72124 5.98289 6.42281 6.20265 6.20266C6.42241 5.9825 6.72063 5.85867 7.03169 5.8584C7.34276 5.85812 7.64119 5.98143 7.86134 6.20119L12.5 10.8399L17.1387 6.20119C17.3588 5.98104 17.6574 5.85736 17.9688 5.85736C18.2801 5.85736 18.5787 5.98104 18.7988 6.20119C19.019 6.42134 19.1427 6.71993 19.1427 7.03127C19.1427 7.34261 19.019 7.6412 18.7988 7.86135L14.1602 12.5L18.7988 17.1387C19.019 17.3588 19.1427 17.6574 19.1427 17.9688C19.1427 18.2801 19.019 18.5787 18.7988 18.7988C18.5787 19.019 18.2801 19.1427 17.9688 19.1427C17.6574 19.1427 17.3588 19.019 17.1387 18.7988L12.5 14.1602L7.86134 18.7988C7.64119 19.019 7.3426 19.1427 7.03126 19.1427C6.71992 19.1427 6.42133 19.019 6.20118 18.7988C5.98103 18.5787 5.85736 18.2801 5.85736 17.9688C5.85736 17.6574 5.98103 17.3588 6.20118 17.1387L10.8428 12.5Z"
      fill="white"
    />
  </svg>
);
