import React from "react";
import { checkIcon } from "../../../Base/SVG";
import { motion } from "framer-motion";
import Slider from "react-slick";

const mobileList = [
  {
    id: "1",
    image: "/images/mobile/1.png",
  },
  {
    id: "2",
    image: "/images/mobile/2.png",
  },
  {
    id: "3",
    image: "/images/mobile/3.png",
  },
  {
    id: "4",
    image: "/images/mobile/4.png",
  },
  {
    id: "5",
    image: "/images/mobile/5.png",
  },
  {
    id: "6",
    image: "/images/mobile/6.png",
  },
  {
    id: "7",
    image: "/images/mobile/7.png",
  },
];
const mobileList2 = [
  {
    id: "1",
    image: "/images/mobile2/7.png",
  },
  {
    id: "2",
    image: "/images/mobile2/6.png",
  },
  {
    id: "3",
    image: "/images/mobile2/1.png",
  },
  {
    id: "4",
    image: "/images/mobile2/2.png",
  },
  {
    id: "5",
    image: "/images/mobile2/3.png",
  },
  {
    id: "6",
    image: "/images/mobile2/4.png",
  },
  {
    id: "7",
    image: "/images/mobile2/5.png",
  },
];
const mobileList3 = [
  {
    id: "1",
    image: "/images/mobile3/1.png",
  },
  {
    id: "2",
    image: "/images/mobile3/2.png",
  },
  {
    id: "3",
    image: "/images/mobile3/3.png",
  },
  {
    id: "4",
    image: "/images/mobile3/4.png",
  },
  {
    id: "5",
    image: "/images/mobile3/5.png",
  },
  {
    id: "6",
    image: "/images/mobile3/6.png",
  },
  {
    id: "7",
    image: "/images/mobile3/7.png",
  },
];
export default function Mobile() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
      className="projectsTab"
    >
      <div className="projects__inner-row">
        <div className="projects__inner-content">
          <h3>Dating App</h3>
          <h6 className="sm">Technologies we used</h6>
          <div className="projects__inner-tech">
            <div className="projectsItem">
              <img src="/images/projects/flutter.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/aws.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/laravel.svg" alt="" />
            </div>
          </div>
          <h6 className="sm">Project Status</h6>
          <div className="progress__wrapper">
            <div className="progress full">
              <div className="progress__inner">
                <div className="progress__bar">
                  <span>{checkIcon}</span>
                </div>
              </div>
            </div>
            <p>100%</p>
          </div>
        </div>
        <MobileSlider modul={mobileList} />
      </div>
      <div className="projects__inner-row">
        <MobileSliderLeft modul={mobileList2} />
        <div className="projects__inner-content sm">
          <h3>Ecommerce</h3>
          <h6 className="sm">Technologies we used</h6>
          <div className="projects__inner-tech">
            <div className="projectsItem">
              <img src="/images/projects/flutter.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/aws.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/kubernetes.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/dotnet.svg" alt="" />
            </div>
          </div>
          <h6 className="sm">Project Status</h6>
          <div className="progress__wrapper">
            <div className="progress">
              <div className="progress__inner">
                <div className="progress__bar" style={{ maxWidth: "100%" }}>
                  <span>{checkIcon}</span>
                </div>
              </div>
            </div>
            <p>100%</p>
          </div>
        </div>
      </div>
      <div className="projects__inner-row">
        <div className="projects__inner-content">
          <h3>Messenger Revolution</h3>
          <h6 className="sm">Technologies we used</h6>
          <div className="projects__inner-tech">
            <div className="projectsItem">
              <img src="/images/projects/dotnet.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/flutter.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/kubernetes.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/aws.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/swift.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/kotlin.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/c++.svg" alt="" />
            </div>
            <div className="projectsItem">
              <img src="/images/projects/go.svg" alt="" />
            </div>
          </div>
          <h6 className="sm">Project Status</h6>
          <div className="progress__wrapper">
            <div className="progress">
              <div className="progress__inner">
                <div className="progress__bar" style={{ maxWidth: "85%" }}>
                  <span>{checkIcon}</span>
                </div>
              </div>
            </div>
            <p>85%</p>
          </div>
        </div>
        <MobileSlider modul={mobileList3} />
      </div>
    </motion.div>
  );
}
const MobileSlider = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 600,
    responsive: [
      {
        breakpoint: 750,
        arrows: false,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="projects__inner-slider">
      {props.modul.map((item, index) => {
        return (
          <div className="projects__inner-slider-item" key={index}>
            <img src={item.image} alt="" />
          </div>
        );
      })}
    </Slider>
  );
};
const MobileSliderLeft = (props) => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 600,
    responsive: [
      {
        breakpoint: 750,
        arrows: false,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="projects__inner-slider left">
      {props.modul.map((item, index) => {
        return (
          <div className="projects__inner-slider-item" key={index}>
            <img src={item.image} alt="" />
          </div>
        );
      })}
    </Slider>
  );
};
